<template>
<div class="import-page" :class="isYofish ? 'yofish-page' : 'xz-page'">
  <div class="import-tips" @click="jumpGuide">
    <span>我不清楚如何导入</span>
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re
      /AAAAAY1BMVEUAAAA0NDQzMzMzMzMzMzM2NjZBQUEzMzMzMzM2NjY4ODhNTU0zMzM0NDQ
      zMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzM0NDQ0NDQ0NDQ1NTU2NjY3Nzc3NzczMzM0NDQ0
      NDQzMzOaRLfAAAAAIHRSTlMA/N+HYQkG2LweGQPl09C1sKuemJKNglpVTxwVEsOncdE/H
      48AAABfSURBVBjTfcw3EsAgEARB5L33fv//SqEAuFNRTNbJiKecCmEqgZs4ANARD59j7p
      Z4tLghnj5H/J86PFscGG4e0DP5mdLq0CKV7Ey5Uoi/Kq6DqtYSvtQpdD0uKdNO8QKS0gt
      Ac/RqWgAAAABJRU5ErkJggg=="
      alt="">
  </div>
  <div class="row row-card margin borders">
    <div class="row-module">
      <span class="desc">选择导入文件</span>
      <div class="select-btn">
        <span>{{fileList && fileList.name || '选择文件导入'}}</span>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re
          /AAAAAY1BMVEUAAAD////Z2dnZ2dna2trl5eXY2NjZ2dnZ2dna2trZ2dnZ2dnb29vc3N
          ze3t7g4ODY2NjZ2dnZ2dnZ2dnZ2dna2trZ2dnd3d3l5eX////Y2NjZ2dnY2NjZ2dnZ2d
          ne3t7Y2Njj/RZrAAAAIHRSTlMABN/YYQf7taiLhllRHRkT5dC8r56YkhYJAdPDg4BxH12
          aZ8cAAABjSURBVBjTbc1JEoAgEANAxBUQRcV9m/+/UjzoMEpuXalU2GFExDAGoPAsgXju
          A+7QbLidUueeRdD0r53Q28e7s0SWjhaVAXBNdD5SpFNuF1dhJX+Nvhaipn7vORGzsDpht
          I8Lv5ULfjAm+M0AAAAASUVORK5CYII="
          alt="">
        <input class="y-uploader__input" type="file" accept=".xls,.csv,.xlsx" name="file"
        @change="handleChange" />
        <!-- <input class="y-uploader__input" type="file" name="file"
        @change="handleChange" /> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="download-module">
      <a v-if="!isYofish" href="https://download-cos.yofish.com/ad-gongjushiyebu/小账管家导入模板-自定义.xls">下载官方模版</a>
      <a v-if="isYofish && isAndroid" href="https://download-cos.yofish.com/yofish-gongjushiyebu/有鱼记账导入模板-自定义.xls">下载官方模版</a>
      <a v-if="isYofish && isIos" href="javascript:;" @click="downloadAction">下载官方模版</a>
      <div class="download-desc desc">仅支持xls/xlsx/csv格式文件，目前仅支持导入收入、支出，其它交易类型如转账等敬请期待后续</div>
    </div>
  </div>
  <div class="row row-card border margin border-top">
    <div class="row-module">
      <span class="desc">导入数据来源</span>
      <div class="select-btn" @click="switchActionSheet('isVisible')">
        <span>{{curDataSource.name}}</span>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re
          /AAAAAY1BMVEUAAAD////Z2dnZ2dna2trl5eXY2NjZ2dnZ2dna2trZ2dnZ2dnb29vc3N
          ze3t7g4ODY2NjZ2dnZ2dnZ2dnZ2dna2trZ2dnd3d3l5eX////Y2NjZ2dnY2NjZ2dnZ2d
          ne3t7Y2Njj/RZrAAAAIHRSTlMABN/YYQf7taiLhllRHRkT5dC8r56YkhYJAdPDg4BxH12
          aZ8cAAABjSURBVBjTbc1JEoAgEANAxBUQRcV9m/+/UjzoMEpuXalU2GFExDAGoPAsgXju
          A+7QbLidUueeRdD0r53Q28e7s0SWjhaVAXBNdD5SpFNuF1dhJX+Nvhaipn7vORGzsDpht
          I8Lv5ULfjAm+M0AAAAASUVORK5CYII="
          alt="">
      </div>
    </div>
  </div>
  <div class="row row-card border-bottom">
    <div class="row-module">
      <span class="desc">导入到哪个账本</span>
      <div class="select-btn" @click="switchActionSheet('isVisible1')">
        <span>{{curBookData.bookName}}</span>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAMAAAA2Re
          /AAAAAY1BMVEUAAAD////Z2dnZ2dna2trl5eXY2NjZ2dnZ2dna2trZ2dnZ2dnb29vc3N
          ze3t7g4ODY2NjZ2dnZ2dnZ2dnZ2dna2trZ2dnd3d3l5eX////Y2NjZ2dnY2NjZ2dnZ2d
          ne3t7Y2Njj/RZrAAAAIHRSTlMABN/YYQf7taiLhllRHRkT5dC8r56YkhYJAdPDg4BxH12
          aZ8cAAABjSURBVBjTbc1JEoAgEANAxBUQRcV9m/+/UjzoMEpuXalU2GFExDAGoPAsgXju
          A+7QbLidUueeRdD0r53Q28e7s0SWjhaVAXBNdD5SpFNuF1dhJX+Nvhaipn7vORGzsDpht
          I8Lv5ULfjAm+M0AAAAASUVORK5CYII="
          alt="">
      </div>
    </div>
  </div>
  <div class="row row-card row-card-btn borders">
    <div class="row-module">
      <div class="import-btn" :class="{ active: fileList }"
       @click="importDataAction">确定导入</div>
    </div>
  </div>
  <div class="row">
    <div class="import-desc desc">注：导入数据默认导入至当前APP登录的账户，导入至其他账户请退出APP重新登录需导入的账户再执行导入</div>
  </div>
  <y-actionsheet :is-visible="isVisible"
    class="book-actionsheet"
    :menu-items="dataSource"
    option-tag="name"
    @close="switchActionSheet('isVisible')"
    @choose="chooseMenuList"
    cancel-txt="关闭"
    :isLockBgScroll="true"></y-actionsheet>
  <y-actionsheet :is-visible="isVisible1"
    class="book-actionsheet"
    :menu-items="booksData1"
    option-tag="bookName"
    @close="switchActionSheet('isVisible1')"
    @choose="chooseMenuList1"
    cancel-txt="关闭"
    :isLockBgScroll="true"></y-actionsheet>
    <y-loading
      v-if="isUploading"
      :is-loading-txt="true"
      :loading-image="isYofish ?
        '//download-cos.yofish.com/yofish-gongjushiyebu/20221201095139626-loading-icon.png'
        : '//download-cos.yofish.com/xz-gongjushiyebu/20220823092148315-loading.png'"
      :loading-txt="isYofish ? '正在导入…' : '正在上传，预计需要1分钟...'" />
    <y-dialog :visible.sync="dialogVisible"
      class="dialog-fail">
      <div class="header" slot="header">提示</div>
      <div class="content">{{dialogDesc}}</div>
      <div class="footer-btns">
        <div class="btn cancel-btn" @click="dialogVisible = false">取消</div>
        <div class="btn sure-btn" @click="importDataAction">导入</div>
      </div>
    </y-dialog>
</div>
</template>
<script>
import YActionsheet from '@/components/actionsheet';
import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import { booksListApi } from '@/api/base1';
import { debounce, userAgentType, utf16toEntities } from '@/utils';
import { getAppStatus } from '@/utils/app';
// import axios from 'axios';

export default {
  name: 'Import',
  components: {
    YActionsheet, YLoading, YDialog,
  },
  data() {
    return {
      isVisible: false,
      isVisible1: false,
      // booksData1: [],
      curDataSource: {
        name: '小账管家',
        value: 1,
      },
      curBookData: {},
      fileList: null,
      isIgnore: false,
      isUploading: false,
      dialogVisible: false,
      dialogDesc: '',
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '35e4c0e2-9535-46f9-a02f-f336f23d8a22';
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
    },
    mobileNo() {
      let value = '';
      if (window?.android) value = window?.android?.jzPhoneNum() || '';
      if (window?.ios) value = window?.ios?.jzPhoneNum() || '';
      return value;
    },
    dataSource() {
      return [
        {
          name: '钱迹',
          value: 4,
        },
        {
          name: '随手记',
          value: 7,
        },
        {
          name: '有鱼记账',
          value: 6, // 1
        },
        {
          name: '鲨鱼记账',
          value: 3,
        },
        {
          name: '一木',
          value: 5,
        },
        {
          name: '小账管家',
          value: 6, // 6
        },
      ];
    },
    appInfo() {
      let appInfo = '{}';
      if (window.android) appInfo = window?.android?.jzAppInfo();
      if (window.ios) appInfo = window?.ios?.jzAppInfo();
      appInfo = JSON.parse(appInfo);
      return appInfo;
    },
    booksData1() {
      let data = '[]';
      if (window.android && window.android.getAccountBooks) data = window?.android?.getAccountBooks() || '[]';
      if (window.ios && window.ios.getAccountBooks) data = window?.ios?.getAccountBooks() || '[]';
      // console.log(window?.ios?.getAccountBooks());
      console.log(data);
      data = JSON.parse(data);
      if (window.ios) {
        data = data.map((item) => ({ ...item, bookName: item.booksName }));
      }
      console.log(data);
      let newData = data.length ? data.find((item) => item.bookName === '日常账本') : '';
      let newBookData = data.length ? data[0] : {};
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.curBookData = newData || newBookData;
      return data;
    },
    isYofish() {
      const { host } = window.location;
      return host.includes('yofish.com');
    },
    isAndroid() {
      return userAgentType().isAndroid;
    },
    isIos() {
      return userAgentType().isPhone;
    },
  },
  created() {
    const { isYofish } = this;
    this.curDataSource = !isYofish ? { name: '小账管家', value: 6 }
      : { name: '有鱼记账', value: 6 };
  },
  mounted() {
    document.title = '数据导入';
  },
  methods: {
    switchActionSheet(param) {
      this[param] = !this[param];
    },
    jumpGuide() {
      this.$router.push('/record/guide');
    },
    handleChange(ev) {
      const { files, value } = ev.target;
      const fileTypes = ['xls', 'xlsx', 'csv'];
      if (!files || !files.length) return;
      let len = value.indexOf('.');
      let type = value.slice(len + 1, value.length);
      if (!fileTypes.includes(type)) {
        return this.$toast({ content: '选择文件格式有误' });
      }
      this.isIgnore = false;
      this.fileList = files[0];
    },
    importDataAction: debounce(function () {
      const { fileList } = this;
      if (!fileList) return false;
      let param = new window.FormData();
      let { name } = this.appInfo;
      // name = encodeURIComponent(name);
      // name = 'com.ttjz';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          name,
        },
      };
      let { bookName } = this.curBookData;
      if (this.yofish) bookName = encodeURIComponent(bookName);
      // console.log(bookName);
      const { value } = this.curDataSource;
      if (this.submiting) return false;
      this.submiting = true;
      this.isUploading = true;
      param.append('ignoreIllegal', this.isIgnore);
      param.append('type', 'mobile');
      param.append('mobileNo', this.mobileNo);
      param.append('cuserId', this.cuserId);
      param.append('importType', value);
      param.append('cbooksName', bookName);
      param.append('excel', fileList);
      const that = this;
      $.ajax({
        type: 'post',
        url: '/h5/import/import_data.go',
        contentType: false,
        processData: false,
        dataType: 'json',
        timeout: 0,
        async: true,
        headers: {
          name,
        },
        data: param,
        success(data) {
          that.isUploading = false;
          that.dialogVisible = false;
          console.log(data, 111);
          if (data?.code === 1) {
            that.isIgnore = false;
            !that.yofish && that.$toast({
              content: '导入成功',
              duration: 3000,
            });
            that.yofish && this.$toast({
              content: '导入成功',
              duration: 3000,
              icon: 'https://download-cos.yofish.com/yofish-gongjushiyebu/2022120111041283-right-icon.png',
              className: 'success-toast',
            });
          } else {
            if (data?.code === -2015) {
              that.dialogDesc = data?.desc || '';
              that.dialogVisible = true;
            } else {
              that.$toast({
                content: data?.desc || '网络异常',
                duration: 3000,
              });
            }
            that.isIgnore = data?.code === -2015;
          }
          that.submiting = false;
        },
        error(e) {
          console.log(e);
          !that.yofish && that.$toast({
            content: e,
            duration: 3000,
          });
          that.yofish && this.$toast({
            content: '导入失败',
            duration: 3000,
            className: 'failed-toast',
          });
          that.submiting = false;
          that.isUploading = false;
          that.dialogVisible = false;
        },
      });
    }, 600, true),
    async getBookList() {
      let res;
      const { cuserId } = this;
      let { name } = this.appInfo;
      if (name) name = encodeURIComponent(name);
      name = 'com.ttjz';
      try {
        res = await booksListApi({ cuserId }, { name });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        let { miniProgramBooks } = res?.results || {};
        let booksTypeList = miniProgramBooks?.booksTypeList || [];
        let shareBookList = miniProgramBooks?.shareBookList || [];
        this.booksData1 = [
          ...booksTypeList,
          ...shareBookList,
        ];
        if (booksTypeList.length) {
          let data = booksTypeList.find((item) => item.cbooksname === '日常账本');
          this.curBookData = data;
        }
      }
    },
    chooseMenuList(data) {
      if (data) {
        this.curDataSource = data;
      }
    },
    chooseMenuList1(data) {
      if (data) {
        this.curBookData = data;
      }
    },
    downloadAction() {
      window?.ios?.downloadFile('https://download-cos.yofish.com/yofish-gongjushiyebu/有鱼记账导入模板-自定义.xls');
    },
  },
};
</script>
<style lang="scss" scoped>
@import './styles/import.scss';
</style>
<style>
.yy-toast.success-toast {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  background: rgba(51,51,51,0.9);
  padding: 0;
  margin-left: -100px;
  margin-top: -100px;
}
.yy-toast.failed-toast {
  width: 200px;
  padding: 0;
  height: 64px;
  line-height: 64px;
  background: rgba(51,51,51,0.9);
  border-radius: 12px;
  margin-left: -100px;
  margin-top: -32px;
}
</style>
